<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-15 11:40:20
 * @LastEditTime: 2021-05-28 13:42:49
 * @FilePath: /shop_frontend/src/views/orderList/EditableCell.vue
-->
<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select
        style="width: 120px;margin-right:4px;"
        v-model="logistics_company"
      >
        <a-select-option :value="el.id" v-for="el in logisticList" :key="el.id"> {{el.name}} </a-select-option>
      </a-select>
      <a-input
        :value="value"
        @change="handleChange"
        @pressEnter="check"
      /><a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || " " }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    logistics: String
  },
  data() {
    return {
      value: this.text,
      editable: false,
      logisticList: [],
      logistics_company: this.logistics
    };
  },
  mounted () {
    this.getLogisticsCompany();
  },
  methods: {
        getLogisticsCompany() {
      this.$axios.get(`/logistics_companies/`).then((res) => {
        console.log(res);
        this.logisticList = res;
      });
    },
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", {text: this.value, logistics: this.logistics_company});
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>
<style lang="less" scoped>
.editable-cell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.editable-cell-input-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > i {
    margin-left: 4px;
  }
}
.editable-cell-icon {
  color: #1890ff;
}
</style>


